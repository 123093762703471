export const pageBackgroundMotifScroll = ($) => {
  const $window = $(window);
  // Multiplier to actual scroll height.
  const scrollSpeed = 0.2;

  const setMotifScroll = ($motif) => {
    const originalStartingPoint = $motif.parent().offset().top;

    let position =
      $window.scrollTop() + $window.height() - originalStartingPoint;

    position = position < 0 ? 0 : position;

    $motif.css("top", position * scrollSpeed);
  };

  $(".js-motifs").each((index, el) => {
    const $motif = $(el);

    $window.on("scroll load resize", (event) => {
      setMotifScroll($motif);
    });

    $window.on("load", (event) => {
      setMotifScroll($motif);
      $motif.removeClass("hidden");
    });
  });
};
