export function fieldClears ($) {
  $('.field-clear').each(function(index, el) {
    const $el = $(el);
    const $input = $el.find('.field-clear--input');
    const $clear = $el.find('.field-clear--button');
    $input.on('change, keyup', function(event) {
      if ($input.val()) {
        $clear.addClass('show');
      } else {
        $clear.removeClass('show');
      }
    });

    $clear.on('click touch', function(event) {
      event.preventDefault();
      $input.val('');
      $clear.removeClass('show');
    });
  });
}