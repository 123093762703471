export function hamburgerMenu ($) {
  const $window = $(window);
  const hamburgerMenu = $('#hamburger-menu');
  const hamburger = $('#hamburger');
  const header = $('.header');

  const toggleMobileMenu = function() {
    hamburgerMenu.toggleClass('active');
    hamburger.toggleClass('clicked');
    header.toggleClass('hamburger-open');
  }

  $('#hamburger').click(function(e){
    e.stopPropagation();
    hamburgerMenu.toggleClass('active');
    hamburger.toggleClass('clicked');
    header.toggleClass('hamburger-open');
  });

  $window.on('resize', function(){
    hamburgerMenu.removeClass('active');
    hamburger.removeClass('clicked');
    header.removeClass('hamburger-open');
  });

  setTimeout(function(){
    hamburgerMenu.removeClass('d-none').addClass('d-flex');
  }, 1000);
}