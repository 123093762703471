export function shaveCardText ($) {
  const $window = $(window);
  const shaveCardText = function() {
    shave(".card--text-size--normal .card--title", 60);
    shave(".card--text-size--normal .card--summary", 110);
    shave(".card--text-size--large .card--title", 78);
    shave(".card--text-size--large .card--summary", 95);
  };

  let cardShaveTimeout;
  $window.on('resize', function(event) {
    clearTimeout( cardShaveTimeout );
    // Add a timeout so the update down't happen every millisecond.
    cardShaveTimeout = setTimeout( function() {
    shaveCardText();
    }, 500 );
  });

  setTimeout(function() {
    shaveCardText();
  }, 1000);
}