import { contactForm } from "./includes/contactForm.js";
import { fieldClears } from "./includes/fieldClears.js";
import { filterableCards } from "./includes/filterableCards.js";
import { hamburgerMenu } from "./includes/hamburgerMenu.js";
import { headerSearch } from "./includes/headerSearch.js";
import { imageSlideshow } from "./includes/imageSlideshow.js";
import { pageBackgroundMotifScroll } from "./includes/pageBackgroundMotifScroll.js";
import { scrollReveals } from "./includes/scrollReveals.js";
import { scrollToTop } from "./includes/scrollToTop.js";
import { shaveCardText } from "./includes/shaveCardText.js";
import { smoothAnchorScrolls } from "./includes/smoothAnchorScrolls.js";

jQuery.noConflict();
(function ($) {
  $(document).ready(function () {
    hamburgerMenu($);

    imageSlideshow();

    shaveCardText($);

    scrollToTop($);

    contactForm($);

    smoothAnchorScrolls($);

    headerSearch($);

    fieldClears($);

    filterableCards($);

    pageBackgroundMotifScroll($);

    scrollReveals($);

    fitvids(".block--body-blocks");
  });
})(jQuery);
