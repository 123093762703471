export function imageSlideshow () {
  const elms = document.getElementsByClassName( 'splide' );
  const settings = {
    perPage: 1,
    lazyLoad: 'nearby',
    type: 'fade',
    rewind: true
  };

  for (let i = 0, len = elms.length; i < len; i++) {
    new Splide( elms[ i ], settings ).mount();
  }
}