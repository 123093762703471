export function filterableCards ($) {
  const $window = $(window);
  $('.searchable-cards').each(function(index, el) {
    const $projectsSearch = $(el);
    const $searchForm = $projectsSearch.find('.searchable-cards--form');
    const $filterToggleButton = $projectsSearch.find('.js--filter-toggle-button');
    const $filtersKeywords = $projectsSearch.find('.form-filters--keywords');
    const $filtersRegions = $projectsSearch.find('.form-filters--regions');
    const $filtersTopics = $projectsSearch.find('.form-filters--topics');
    const keywordDelimiter = ', ';
    const hiddenFieldDelimiter = ',';
    const $keywordsInput = $projectsSearch.find('#keywordsInput');
    const $regionsDropdown = $projectsSearch.find('#regionsDropdown');
    const $topicsDropdown = $projectsSearch.find('#topicsDropdown');
    const regionsDropdownId = '#regionsDropdown';
    const topicsDropdownId = '#topicsDropdown';

    let state = {
      keywords: {
        string: '',
        data: '',
      },
      regions: {
        string: '',
        data: '',
      },
      topics: {
        string: '',
        data: '',
      }
    };

    // We will populate this later if the grid exists.
    const updateGrid = {run: function() {}};

    /**
     * Update the state and the URL.
     */
    const updateState = function() {
      state = {
        keywords: {
          string: $keywordsInput.find('.autocomplete-input').val(),
          data: $filtersKeywords.val(),
        },
        regions: {
          string: $regionsDropdown.html(),
          data: $filtersRegions.val(),
        },
        topics: {
          string: $topicsDropdown.html(),
          data: $filtersTopics.val(),
        }
      };

      // Update state on the url.
      // Check if there is no data.
      let newQueryString = '';
      if (state.keywords.data != '' ||
          state.regions.data != '' ||
          state.topics.data != '') {
        const parsed = queryString.parse(location.search);
        parsed.state = JSON.stringify(state);
        newQueryString = '?' + queryString.stringify(parsed);
      }

      if (history.pushState) {
        var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + newQueryString;
        window.history.pushState({path:newurl},'',newurl);
      }

      const updated = queryString.parse(location.search);
    }

    /**
     * Filter menu toggle
     */
    const showFilters = function() {
      $searchForm.slideDown();
    }
    $filterToggleButton.each(function(index, el) {
      const $button = $(el);
      $button.on('click touch', function(event) {
        event.preventDefault();
        if ($button.hasClass('open')) {
          $searchForm.slideUp();
          $button.removeClass('open');
        } else {
          $button.addClass('open');
          showFilters();
        }
      });
    });

    $window.on('resize', function(event) {
      if ($window.width() >= 1300) {
        $filterToggleButton.each(function(index, el) {
          $(el).addClass('open');
        });
        showFilters();
      }
    });

    /**
     * Isotope
     */
    if ($projectsSearch.find('.grid')) {
      const cardsIsotope = new Isotope('.grid', {
        itemSelector: '.grid-item',
        layoutMode: 'masonry',
        percentPosition: true,
        masonry: {
          columnWidth: '.grid-sizer'
        }
      });

      imagesloaded($projectsSearch.find('.grid')).on('progress', function() {
        // layout Isotope after each image loads
        cardsIsotope.layout();
      });

      const $noResults = $projectsSearch.find('.search--no-results');
      updateGrid.run = function() {
        const keywordsClasses = $filtersKeywords.val() ?
          '.' + _.join(_.split($filtersKeywords.val(), hiddenFieldDelimiter), '.') : '';
        const regionClass = $filtersRegions.val() ?
          '.' + _.trim($filtersRegions.val()) : '';
        const topicClass = $filtersTopics.val() ?
          '.' + _.trim($filtersTopics.val()): '';

        const combinedFilters = keywordsClasses + regionClass + topicClass;

        cardsIsotope.arrange({ filter: _.trim(combinedFilters) == '' ? '*' : combinedFilters });

        // Update count.
        $projectsSearch.find('.block--cards--heading .block--cards--heading--content').each(function(index, el) {
          const elements = cardsIsotope.getFilteredItemElements();
          const countText = elements.length == 1 ?
            '1 project' : elements.length + ' projects';
          $(el).html(countText);

          if (elements.length < 1) {
            $noResults.removeClass('d-none');
          } else {
            $noResults.addClass('d-none');
          }
        });

        updateState();
      };
    }

    /**
     * Autocomplete
     */
    $keywordsInput.each(function(index, el) {
      const $el = $(el);
      const $input = $el.find('.autocomplete-input');
      const $clear = $el.find('.autocomplete-clear');
      const $list = $el.find('.autocomplete-list');

      $input.attr('id', 'autoComplete-' + index);
      $input.siblings('label').attr('for', 'autoComplete-' + index);

      if (!$list.length || !$input.length) {
        return;
      }

      let values = $list.val();
      // This delimiter is used in templates/common/includes/partials/_autocomplete.twig
      values = values.split('-|-');

      if (values.length > 0) {
        const autoCompleteJS = new autoComplete({
          selector: '#autoComplete-' + index,
          placeHolder: "Search...",
          data: {
            src: values
          },
          threshold: 2,
          query: {
            manipulate: (query) => {
              // Just get the last item.
              return _.lowerCase(_.trim(_.last(_.split(query, keywordDelimiter))));
            }
          },
          resultsList: {
            noResults: (list, query) => {
              // Create "No Results" message list element
              const message = document.createElement("li");
              message.setAttribute("class", "no_result");
              // Add message text content.
              message.innerHTML = `<span>Found no results for "${query}"</span>`;
              // Add message list element to the list
              list.appendChild(message);
            },
          },
          resultItem: {
            highlight: {
                render: true
            }
          },
          onSelection: (feedback) => {
            // Get the value.
            const selection = feedback.selection.value;

            const currentContents = _.split($input.val(), keywordDelimiter);
            const olderSelections = _.slice(currentContents, 0, currentContents.length - 1);

            // Update the value of the input field.
            if (olderSelections.length > 0) {
              $input.val(_.join(olderSelections, keywordDelimiter) + keywordDelimiter + selection + keywordDelimiter);
            } else {
              $input.val(selection != '' ? selection + keywordDelimiter : selection);
            }
            $input.focus();

            // Update the hidden field.
            const allSelections = _.union(olderSelections, [selection]);
            const selectionClasses = _.map(allSelections, function(x) {
              return 'keyword-' + _.kebabCase(_.trim(x));
            });
            $filtersKeywords.val(_.join(selectionClasses, hiddenFieldDelimiter));

            updateGrid.run();
          }
        });

        // Update if the field is manually updated.
        let timeout;
        $input.on('change, keyup', function(event) {
          clearTimeout( timeout );
          // Add a timeout so the update down't happen every millisecond.
          timeout = setTimeout( function() {
            const inputValue = $input.val();
            let contents = inputValue.includes(keywordDelimiter) ?
              _.split(inputValue, keywordDelimiter) : [inputValue];
            // Must have a minimum of 2 characters to count.
            contents = _.filter(contents, function(x) { return x.length >= 2 ; });
            const selectionClasses = _.map(contents, function(x) {
              return 'keyword-' + _.kebabCase(_.trim(x));
            });
            $filtersKeywords.val(_.join(selectionClasses, hiddenFieldDelimiter));

            updateGrid.run();
          }, 500 );
        });

      }
    });

    /**
     * Search
     */
    $projectsSearch.find('.searchable-cards--form').each(function(index, el) {
      const $form = $(el);

      const bindDropdownClick = function($dropdown, $hiddenField, prefix) {
        $dropdown.find('+ .dropdown-menu a').on('click', function(event) {
          event.preventDefault();
          const dropdownItem = $(this);
          // Update the hidden field.
          $hiddenField.val(dropdownItem.data('value') ? prefix + dropdownItem.data('value') : '');
          dropdownItem.closest('.dropdown').find('.dropdown-toggle').html(dropdownItem.html());

          updateGrid.run();
        });
      }

      bindDropdownClick($regionsDropdown, $filtersRegions, 'region-');
      bindDropdownClick($topicsDropdown, $filtersTopics, 'topic-');
    });

    /**
     * Clear the autocomplete
     */
    const clearAutocomplete = function() {
      $keywordsInput.find('.autocomplete-input').val('');
      $keywordsInput.find('.autocomplete-clear').removeClass('show');
      $filtersKeywords.val('');
    }

    /**
     * Clear the autocomplete
     */
    const clearRegions = function() {
      $regionsDropdown.html('All regions');
      $filtersRegions.val('');
    }

    /**
     * Clear the autocomplete
     */
    const clearTopics = function() {
      $topicsDropdown.html('All topics');
      $filtersTopics.val('');
    }

    /**
     * Clear all filters is clicked
     */
    $projectsSearch.find('.clear-search-form').on('click touch', function(event) {
      event.preventDefault();

      clearAutocomplete();
      clearRegions();
      clearTopics();

      updateGrid.run();
    });

    /**
     * Autocomplete clear is clicked
     */
    $projectsSearch.find('.autocomplete-clear').on('click touch', function(event) {
      event.preventDefault();

      clearAutocomplete();

      updateGrid.run();
    });

    const loadLinkVariableToState = function() {
      const loadedQueryString = queryString.parse(location.search);
      if (loadedQueryString.filterKeyword) {
        state.keywords.string = _.trim(loadedQueryString.filterKeyword);
        state.keywords.data = 'keyword-' + _.kebabCase(_.trim(loadedQueryString.filterKeyword));
      }
      if (loadedQueryString.filterRegion) {
        state.regions.string = _.trim(loadedQueryString.filterRegion);
        state.regions.data = 'region-' + _.kebabCase(_.trim(loadedQueryString.filterRegion));
      }
      if (loadedQueryString.filterTopic) {
        state.topics.string = _.trim(loadedQueryString.filterTopic);
        state.topics.data = 'topic-' + _.kebabCase(_.trim(loadedQueryString.filterTopic));
      }
    }

    /**
     * Load the state from the URL and update the form.
     */
    const loadState = function() {
      const loadedQueryString = queryString.parse(location.search);
      if (loadedQueryString.state) {
        state = JSON.parse(loadedQueryString.state);
      }

      // Check if there is no data.
      if (state.keywords.data == '' &&
          state.regions.data == '' &&
          state.topics.data == '') {
        return;
      }

      if (state.keywords.data !== '') {
        $filtersKeywords.val(state.keywords.data);
        $keywordsInput.find('.autocomplete-input').val(state.keywords.string);
        $keywordsInput.find('.autocomplete-clear').addClass('show');
      }
      if (state.regions.data !== '') {
      $filtersRegions.val(state.regions.data);
      $regionsDropdown.html(state.regions.string);
      }
      if (state.topics.data !== '') {
        $filtersTopics.val(state.topics.data);
        $topicsDropdown.html(state.topics.string);
      }


      updateGrid.run();
    };

    // Load stuff from the query string.
    loadLinkVariableToState();
    loadState();
  });
}