export function contactForm ($) {
  $('.contact-form').each(function(index, el) {
    const $contactForm = $(el);
    const buttonContainer = $contactForm.find('.form-submit-container');
    buttonContainer.removeClass('d-flex').addClass('d-none');

    $contactForm.find('.form-submit-display').on('click touch', function(event) {
      event.preventDefault();
      buttonContainer.find('input').trigger('click');
    }).on('keyup', function(event) {
      event.preventDefault();
      // Spacebar
      if(event.keyCode == 32) {
        buttonContainer.find('input').trigger('click');
      }
    });
  });
}