export function headerSearch ($) {
  const $window = $(window);

  $('.header--search-form').each(function(index, el) {
    const formContainer = $(el);
    const form = formContainer.find('.header--search-form--form');
    const desktopNav = formContainer.siblings('.nav-desktop');

    const openSearch = function() {
      formContainer.addClass('show');
      form.addClass('show');
      desktopNav.addClass('hidden');
    }
    const closeSearch = function() {
      formContainer.removeClass('show');
      form.removeClass('show');
      desktopNav.removeClass('hidden');
      form.find('input').val('');
    }

    // Initialise.
    form.removeClass('d-none');

    formContainer.find('.header--search-form--toggle').on('click touch', function(event) {
      event.preventDefault();
      /* Act on the event */
      if (form.hasClass('show')) {
        closeSearch();
      } else {
        openSearch();
      }
    });

    $window.on('resize', function(){
      closeSearch();
    });
  });
}