export function scrollToTop ($) {
  const $window = $(window);
  const scrolls = $('.js-scroll-to-top');
  const cssHidden = {
    opacity: '0',
    bottom: '-50px',
    'pointer-events': 'none'
  };
  const cssVisible = {
    opacity: '1',
    bottom: '50px',
    'pointer-events': 'auto'
  };

  scrolls.each(function(index, el) {
    const $scrollButton = $(el);
    $scrollButton.css({
      position: 'fixed'
    }).css(cssHidden).on('click touch', function(event) {
      event.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, "slow");
    });
  });

  if (scrolls.length) {
    $window.on('scroll', function(event) {
      event.preventDefault();
      /* Act on the event */
      if ($window.scrollTop() > 0) {
        scrolls.each(function(index, el) {
          $(el).css(cssVisible);
        });
      } else {
        scrolls.each(function(index, el) {
          $(el).css(cssHidden);
        });
      }
    });
  }
}